import { defineNuxtRouteMiddleware, navigateTo } from '#imports';

const LOGIN_PATH = '/login';

export default defineNuxtRouteMiddleware(async () => {
  const { $supabase } = useNuxtApp();
  const user = await $supabase.auth.getUser();

  if (user.error) {
    return navigateTo(LOGIN_PATH);
  }
});

